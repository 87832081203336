/* swiper */ 

  
  .ad {
    font-size: 18px;
    color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 80px 60px;
  }
  
 