
*{margin: 0;padding: 0;}
ol , ul{
  padding-left: 0;
}
html{
  scroll-behavior: smooth;
}

body{background-color: #F7F4F1; font-family: 'De Luxe Condensed', sans-serif;}

.header-top{background-color: #F7F4F1;}

.btn-style .btn:hover {background-color:#990000}

.one{
   height: 100vh !important;

  scroll-snap-align:end;
}
.two{ 
  height: 100vh !important;

  scroll-snap-align:start end;
}
.three{
   height: 100vh !important;

  scroll-snap-align:end;
}
.four{ 
  height: 100vh !important;
  scroll-snap-align:end;

}
.five{
   height: 100vh !important;
   scroll-snap-align:start;

}
.six{ 
  height: 100vh !important;
  scroll-snap-align:start;

}
#root{
  scroll-snap-type: y mandatory;
  overflow-y:scroll;
  height: 100vh;
  width: 100%;
}


/* header top */
.header-top{display: flex;
  justify-content: center;
  height: 131px;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
  width: calc(100% - 15px );
  left: 0px;
  overflow: visible;
  font-family: 'De Luxe Light Condensed', sans-serif;
  z-index: 1000;
  position: fixed;
  box-sizing: border-box;
  
}
  .header-menu-1{
    display: flex;
    width: calc(50% - 70px);
    justify-content: space-evenly;
    list-style: none;
    margin-bottom: -1rem;
  }
  .header-menu-1 li{
    display: flex;
    align-self: stretch;
    position: relative;
    text-align: center;
  }
  .header-menu-1 li:nth-of-type(n+4){
    -webkit-box-ordinal-group: 3;
          order: 2;
  }
  .header-menu-1 li a{
    position: relative;
    display: inline-block;
    font-size: 17px;
    padding: 0;
    margin-top: 25px;
    color: #ED2124;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  .header-menu-2 li{
    display: flex;
    align-self: stretch;
    position: relative;
    text-align: center;
    padding: 5px 30px;
  }

.header-menu-2 li a:hover{
  color: #000;
  text-decoration:underline;
  text-decoration-color: red;
  text-decoration-thickness: 3px;
  transition: .30s linear;
  -webkit-transition: .30s linear;
  -moz-transition: .30s linear;
  -ms-transition: .30s linear;
  -o-transition: .30s linear;
}

.header-menu-1 li a:hover{
  color: #000;
  text-decoration:underline;
  text-decoration-color: red;
  text-decoration-thickness: 3px;
  transition: .30s linear;
  -webkit-transition: .30s linear;
  -moz-transition: .30s linear;
  -ms-transition: .30s linear;
  -o-transition: .30s linear;
}

  .header-menu-2 li a{
    position: relative;
    display: inline-block;
    font-size: 17px;
    padding: 0;
    margin-top: 25px;
    color: #ED2124;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  .header-logo{
    display: inline-block;
    vertical-align: top;
    height: 183px;
  width: 208px;
  z-index: 2;
}
  .header-logo img{
    width: 190px;
    height: auto;
  }
.header-menu-2{
  display: flex;
  justify-content: space-evenly;
  width: calc(50% - 132px);
  list-style: none;
  margin-bottom: -1rem;
}

.hamburger{
  display: none;
}

/* btn visit */
.visit-btn{
  position: relative;
  bottom: 34px;
  text-decoration: none;
  color: #ED2124;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 15px;

}
.visit-btn:hover{
  color: #000;
  text-decoration:underline;
  text-decoration-color: red;
  text-decoration-thickness: 3px;
}

/* btn visit */
.section-title h2{
    font-family: Copperplate, "Copperplate Gothic Light", fantasy;
    padding: 10% 0 1%;
    font-size: 2.2rem;
    text-align: center;
    font-weight: 900;
}

.image-card img{
  width: 100%;
  height: 220px;
}
.card{
  height: 475px;
  width: 330px;
  margin: 10px;
  box-shadow: 0px 1px 39px -14px #ff000042;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.product-details{padding: 12px; background-color: white;text-align: center;}
.product-details h2 {color: #CC0000;padding: 4px 0; font-size: 21px;font-weight: 600;}
.product-details p {word-spacing: 8px;font-weight: 600;font-size: 12px;}
.product-details .btn-click {
  width: 165px;
  height: 37px;
  background-color: #CC0000;
  color: white;
  border: none;
  font-weight: 800;
}
.product-details .btn-click:hover::after{
  content: '→';
  display: inline-block;
  color: white;
  left: 6px;
  position: relative;
  transition: 4s;
  -webkit-transition: 4s;
  -moz-transition: 4s;
  -ms-transition: 4s;
  -o-transition: 4s;
}

.product-container{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
}

.title-product::before{
  
  content: "";
  position: relative;
  top: 50%;
  bottom: auto;
  display: inline-block;
  width: 31px;
  height: 17px;
   right: 20px;
   background:url(https://d2z05otmbim3z8.cloudfront.net/wp-content/uploads/2019/03/07122459/Dots_left.svg) no-repeat; 
}
.title-product::after{
  content: "";
  position: relative;
  top: 50%;
  bottom: auto;
  display: inline-block;
  width: 31px;
  height: 17px;
   left: 20px;
  background:url(https://d2z05otmbim3z8.cloudfront.net/wp-content/uploads/2019/03/07122501/Dots_right.svg) no-repeat;
}
.product-details h2::after {
  content: "";
  display:-webkit-box;
  width: 89px;
  height: 5px;
  margin: 11px auto;
  background:url(https://d2z05otmbim3z8.cloudfront.net/wp-content/uploads/2019/03/07132507/Sdivider_red_small.svg) no-repeat;
}

/* Footer */

.footer{
  padding-top: 12%;
}
.pre-footer{
    margin: 0rem 15rem 0rem;
}
.footer-sing{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.footer-sing p{
  color: #CC0000;
  font-weight: 900;
  padding-top: 20px;
  height: 100px;
  font-size: 1.2rem;
  text-align: center;
}
.footer-sing p::after{
  content: "";
  display: block;
  width: 127px;
  height: 12px;
  margin: 11px auto;
  background:url(https://d2z05otmbim3z8.cloudfront.net/wp-content/uploads/2019/03/07132507/Sdivider_red_small.svg) no-repeat ;
}
.footer-sing a{
  color: white;
  background-color: #c00;
  text-align: center;
  text-decoration: none;
  padding: 10px;
  font-weight: 600;
}
.footer-sing ul {transform: translate(32px, -11px);}
.footer-sing ol ul {padding-left: 0rem;}
.footer-sing li{list-style: none;float: left;margin: 0 12px;font-size: 45px;color: #c00;}


.bottom-footer{}
.bottom-footer ul {display: flex;justify-content: center;flex-wrap: wrap;}
.bottom-footer li{float: left;list-style: none; margin-right: 40px;}
.bottom-footer a{color: black;text-decoration: none;}
.bottom-footer a:hover{ text-decoration:underline; }
.bottom-footer{    padding: 35px 0 200px;
      background: url(./images/Footer.png) no-repeat center 80%/90% auto, no-repeat center bottom/100% 10%;}

.footer-responsibly h4{margin-top: 45px;text-align: center;font-weight: 800 !important;font:bold 1.30rem Copperplate, "Copperplate Gothic Light", fantasy;}
.footer-responsibly-elem p{text-align: center;margin-bottom: 4px;font-family: Copperplate, "Copperplate Gothic Light", fantasy;font-size: 12px;font-weight: 600;}


/* Footer */


/* details section slide */

.details-section{
  width: 100%;
  height: 485px;
  background-color: #c00;
  position: relative;


}
.details-section::before{
    content: '';
    position: absolute;
    top: -7px;
    width: 100%;
    height: 100%;
    bottom: -7px;
    left: 0;
    right: 0;
    z-index: 0;
    background: url(https://d2z05otmbim3z8.cloudfront.net/wp-content/uploads/2019/04/04091548/Pattern_background_white1.png);
    background-position: left top;
    background-size: 20%;
    opacity: .6;
}

.crsl{
  padding: 50px 16px;
}

/* details section */

/* slide featured section */
.featured-section{
  width: 100%;
  height: 550px;
  background-color: #FFFFFF;
  position: relative;
}
.featured-section::before{
  content: '';
  position: absolute;
  top: -9px;
  width: 100%;
  height: 103%;
  bottom: -7px;
  left: 0;
  right: 0;
  z-index: 0;
  background: url(https://d2z05otmbim3z8.cloudfront.net/wp-content/uploads/2019/04/01124218/Pattern_background.png);
  background-position: left top;
  background-size: 20%;
  opacity: .6;
}
.crs2{padding: 30px 20px;}




.swiper-slide .title-featured {
  font-size: 2rem;
  font-weight: 600;
  font-family: Copperplate, "Copperplate Gothic Light", fantasy;
  padding: 15px 0;
  color: #000;
  
  display: inline;
}
.swiper-slide .btn-seeMore{
  background-color: #c00;
  display: inline;
  padding: 10px 30px 12px;
  position: absolute;
  font-weight: 600;
  
}
.swiper-slide .btn-seeMore a{
  
  padding:30px;
  font-size: 18px;
  text-decoration: none;
  color: white;
  
}
.swiper-slide .slid-section{
  padding: 20px;
}

.swiper-slide .subtitle-featured {
  font-size: 19px;
  background-color: #F7F4F1;
  color:#c00;
  display: block;
  padding: 5px;
  font-weight: 600;
  font-family: Copperplate, "Copperplate Gothic Light", fantasy;
}
.swiper-slide .subtitle-featured::before {
  content: "";
    position: relative;
    top: 50%;
    bottom: auto;
    display: inline-block;
    width: 31px;
    height: 2px;
    right: 8px;
    vertical-align: middle;
   background-color: #CC0000;
}
.swiper-slide .subtitle-featured::after {
  content: "";
    position: relative;
    top: 50%;
    bottom: auto;
    display: inline-block;
    width: 31px;
    height: 2px;
    left: 8px;
    vertical-align: middle;
   background-color: #CC0000;
}
.swiper-slide .text-featured {
  font-size: 19px;
  max-width: 400px;
  line-height: 1.3;
  background-color: #F7F4F1;
  color: #000;
  opacity: 0.9;
  margin: -8rem 2rem;
  padding: 10px;
}

.swiper-slide .btn-seeMore-featured{
  background-color: #c00;
  display: inline;
  padding: 10px 30px 12px;
  position: absolute;
  font-weight: 600;
  left: 0;
  
}
.btn-section a:hover{
  padding-left: 29px;
  transition:0.9s ;
  -webkit-transition:0.9s ;
  -moz-transition:0.9s ;
  -ms-transition:0.9s ;
  -o-transition:0.9s ;
}
.swiper-slide .btn-seeMore-featured a{
  
  padding:30px;
  font-size: 18px;
  text-decoration: none;
  color: white;
  letter-spacing: 1px;
}

/* slide featured section */

/* slide product section */

.swiper-slide .Product-title{
  color:#c00;
  font-weight: 700;
  font-size: 20px;
  position: absolute;
  top: 45%;
  margin: 0;
  padding: 0 50px;
  text-align: center;
  background-color: #f7f4f1db;
}
.swiper-slide .Product-title::after{
  content: "";
  display: -webkit-box;
  width: 89px;
  height: 16px;
  margin: 1px auto;
  background: url(https://d2z05otmbim3z8.cloudfront.net/wp-content/uploads/2019/03/07132507/Sdivider_red_small.svg) no-repeat;
 
}

.btn-section{
  text-align: center;
  padding: 45px;
  align-items: center;
}
.btn-section a:hover{
  background-color: #990000;
}
.btn-section a{
  
  padding: 11px 35px;
  background-color: #c00;
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 15px;
  
}
.btn-section a:hover::after{
  content: '→';
  display: inline-block;
  color: white;
  left: 6px;
  position: relative;
  transition: 3s;
  -webkit-transition: 3s;
  -moz-transition: 3s;
  -ms-transition: 3s;
  -o-transition: 3s;
}

/* slide product secytion */

@media (max-width: 200px){
  .visit-btn{
    position: relative;
    top: 4px;
    text-decoration: none;
    color: #ED2124;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 15px;
}
}

/* small devices (phones , 300 and up) */

@media (max-width: 300px){
  .header-top{justify-content: space-between;left: ;}
  .swiper-slide .text-featured{
    font-size: 19px;
    max-width: 444px;
    line-height: 1.3;
    background-color: #F7F4F1;
    color: #000;
    opacity: 0.9;
    margin: -9rem -3rem;
    padding: 19px;
  }
}
/* Small devices (tablets, 768px and up) */
@media (max-width: 768px) {
  
  .four{ 
    height: 200vh !important;
    scroll-snap-align:end;
  
  }

  .swiper-slide .text-featured{
    font-size: 19px;
    max-width: 444px;
    line-height: 1.3;
    background-color: #F7F4F1;
    color: #000;
    opacity: 0.9;
    margin: -9rem -3rem;
    padding: 19px;
  }
.visit-btn{
    position: relative;
    top: 4px;
    text-decoration: none;
    display: block;
    color: #ED2124;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 15px;
    right: 10px;
}
  .header-top{
    justify-content: space-between;
  }
.hamburger{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ED2124;
    font-size: 1.6rem;
    cursor: pointer;
}
.header-menu-1{
    position: fixed;
    display: none;
    top: 50%;
    align-items: center;
    flex-direction: column;
    position: absolute;
    background-color: #FFFFFF;
    transition: 0.9s;
    -webkit-transition: 0.9s;
    -moz-transition: 0.9s;
    -ms-transition: 0.9s;
    -o-transition: 0.9s;
}
.header-menu-2{
  position: fixed;
  display: none;
  top: 50%;
  align-items: center;
  flex-direction: column;
  position: absolute;
  background-color: #FFFFFF;
  transition: 0.9s;
  -webkit-transition: 0.9s;
  -moz-transition: 0.9s;
  -ms-transition: 0.9s;
  -o-transition: 0.9s;
}

.header-menu-1.active{
  top: 130px;
  right: 0;
  width: 50%;
  display: block;
  align-items: center;
  height: 15rem;
  font-size: 14px;
  justify-content: flex-start;
  transition: 0.9s;
  background-color: #F7F4F1;
  z-index: 3;
}
.header-menu-2.active{
  top: 130px;
  left: 0;
  width: 50%;
  display: block;
  align-items: center;
  height: 15rem;
  font-size: 14px;
  justify-content: flex-start;
  transition: 0.9s;
  background-color: #F7F4F1;
  z-index: 3;
}

/* .product-container {
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  grid-template-columns: repeat(1,22rem);
  justify-content: center;
} */
.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 200px 60px;
}

.footer-sing p {
  color: #CC0000;
  font-weight: 900;
  padding-top: 20px;
  height: 120px;
  font-size: 1.4rem;
  text-align: center;
}
.pre-footer{
    
  margin: 5rem 4rem;
}

}

/* Medium devices (desktops, 992px and up) */
@media (max-width: 992px) {
  .footer-sing p {
    color: #CC0000;
    font-weight: 900;
    padding-top: 20px;
    height: 180px;
    font-size: 1.4rem;
    text-align: center;
  }
  .pre-footer{
    
    margin: 5rem 4rem;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}




/* swiper */ 
.swiper {
  width: 100%;
  height: 100%;
  background: #000;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 250px 60px;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 110%;
  -webkit-background-size: cover;
  background-size:auto;
  background-position: center;
  
}

.swiper-slide .title {
  font-size: 2rem;
  font-weight: 600;
  font-family: Copperplate, "Copperplate Gothic Light", fantasy;
  padding: 15px 0;
  color: #000;
  
  display: inline;
}
.swiper-slide .btn-seeMore{
  background-color: #c00;
  display: inline;
  padding: 10px 30px 12px;
  position: absolute;
  font-weight: 600;
  
}
.swiper-slide .btn-seeMore:hover{
 background-color: #990000;
}
.swiper-slide .btn-seeMore a:hover{
  font-size: 18px;
  padding-left: 19px;
  transition:0.9s ;
  -webkit-transition:0.9s ;
  -moz-transition:0.9s ;
  -ms-transition:0.9s ;
  -o-transition:0.9s ;
}
.swiper-slide .btn-seeMore a:hover::after{
  content: '→';
  display: inline;
  color: white;
  left: 6px;
  bottom: -1px;
  position: relative;
  transition: 3s;
  -webkit-transition: 3s;
  -moz-transition: 3s;
  -ms-transition: 3s;
  -o-transition: 3s;
  
}
.swiper-slide .btn-seeMore a{
  
  padding:30px;
  font-size: 18px;
  text-decoration: none;
  color: white;
  
}
.swiper-slide .slid-section{
  padding: 20px;
}

.swiper-slide .subtitle {
  font-size: 19px;
  background-color: #F7F4F1;
  color:#c00;
  display: block;
  padding: 5px;
  font-weight: 600;
  font-family: Copperplate, "Copperplate Gothic Light", fantasy;
}
.swiper-slide .subtitle::before {
  content: "";
    position: relative;
    top: 50%;
    bottom: auto;
    display: inline-block;
    width: 31px;
    height: 2px;
    right: 8px;
    vertical-align: middle;
   background-color: #CC0000;
}
.swiper-slide .subtitle::after {
  content: "";
    position: relative;
    top: 50%;
    bottom: auto;
    display: inline-block;
    width: 31px;
    height: 2px;
    left: 8px;
    vertical-align: middle;
   background-color: #CC0000;
}
.swiper-slide .text {
  font-size: 19px;
  max-width: 400px;
  line-height: 1.3;
  background-color: #F7F4F1;
  color: #000;
  padding: 20px;
  opacity: 0.9;
  margin-top: 1rem;
}

.swiper-button-next, .swiper-button-prev{
  top: 88% !important;
  width: calc(var(--swiper-navigation-size)/ 44 * 230) !important;
}
/*  navigation flech icon */
.swiper-button-next:after, .swiper-button-prev:after{color:white;font-weight: 900;}

/* navigation icons ... */
.swiper-pagination-bullet{
      width: 19px !important;
      height: 19px !important;
      display: inline-block;
      border-radius: 50%;
      background:#c00 !important;
      border: 4px solid white;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
     bottom: 20px !important;
      left: 0;
      width: 100%;}
.swiper-button-next:after, .swiper-button-prev:after{font-size: 33px !important;}
/* swiper */

/* Swipper Multi */


/* Swipper Multi */





/****************  header page  *****************/

.pre-header-page{
  height: 131px;
}
.header-page{
  background: url(./images/maxresdefault.jpg) 0/100% 100%  no-repeat;
  height: 40vw;
  font-weight: 800;
  color: white;
  font-size: 3.5vw;
  font-family: Copperplate, "Copperplate Gothic Light", fantasy;
  text-shadow: 0px 1px, 1px 0px, 1px 1px;
  letter-spacing: 5px;
}
.page-histoia{
  background: url(./images/bg-historia.jpg) 0/100% 100%  no-repeat;
}
.product-page{
  background: url(./images/bg-product.jpg) 0/100% 100%  no-repeat;
}
.meriendas-page{
  background: url(./images/bg-meriendas.jpg) 0/100% 100%  no-repeat;
}
.desayunos-page{
  background: url(./images/bg-desayunos.jpg) 0/100% 100%  no-repeat;
}
.page-boutique{
  background: url(./images/bg-boutique.jpg) 0/100% 100%  no-repeat;
}
.page-cocinas{
  background: url(./images/bg-cocinas.jpg) 0/100% 100%  no-repeat;
}


.header-page-title{position: relative; top: 35%;left: 15%;}
.header-page img {
  position: relative;
    top: 85%;
    left: 25%;
    height: 50px;
}
/****************  header page  *****************/

/****************  contact page  *****************/


.contact-section{
  margin: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: center;
}
.contact-infos{
  display: flex;
  margin: 15px;
  justify-content: center;
  flex-wrap: wrap;
}
.contact-icon{
  width: 288px;
    display: flex;
    flex-direction: column;
    color: #ED2124;
    font-weight: 600;
  
}
.contact-icon a{
  color: black;
  text-decoration: none;
  
}
.contact-icon span{
  font-size: 25px;
  
}
.contact-form{
  padding: 12px;
}
.contact-form form{
  display: flex;
  flex-direction: column;
}
.contact-form input{margin:15px 0 ; border: none; padding: 6px 23px;border-bottom: 1px solid #ED2124;}
.contact-form input[name="message"]{

  height: 60px;
}
.contact-form button{background-color: #ED2124;color: white; border: none; padding: 5px 0;}
.map-section iframe{
  width: 100%;
  height: 400px;
  box-shadow:0px 0px 20px -15px #c5939b;
}
/****************  contact page  *****************/



/****************  history page  *****************/

.history-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.history-card{
  width: 500px;
  margin: 40px;
  box-shadow: 0px 0px 43px -30px #CC0000;
}
.history-date{
  color:#CC0000;
  font-weight: 600;
  padding: 3px;
}
.history-infos{
  padding: 5px;
}

.history-card-img{
  width: 100%;
  height: 400px;
}
/****************  history page  *****************/